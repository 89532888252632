import vodfond from './hero-fond.mp4';
import './App.css';

function App() {
  return (
    <div className="App">
      <video autoPlay loop muted>
        <source src={vodfond} type="video/mp4" />
      </video>
      <div className="content">
        <h1>Portfolio Prochainement</h1>
        <p>Prévu pour le Lundi 18 Novembre</p>
      </div>
    </div>
  );
}

export default App;
